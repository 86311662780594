<template>
    <section class="selected-vehicles section">
        <h2 class="section__heading">
            {{ $t('sections.selected_vehicles.heading', { count: platformVehicles.roundedCount }) }}
        </h2>

        <LoadingIndicator v-if="$fetchState.pending" class="selected-vehicles__loading">
            {{ $t('loading.vehicles_loading') }}
        </LoadingIndicator>

        <!-- Desktop -->
        <VehicleList
            v-if="isLargeScreen"
            :vehicles="selectedBest"
            class="selected-vehicles__list wrap"
            :list-name="listName"
        >
            <SelectedVehiclesTitle
                v-for="(element, index) in selectedBest"
                :key="index"
                :slot="`title-slot-${index}`"
                :title="titles[index]"
            />
        </VehicleList>
        <!-- Mobile -->
        <template v-else>
            <div v-for="(type, index) in selected" :key="index" class="selected-vehicles__mobile-wrapper">
                <SelectedVehiclesTitle :title="titles[index]" />
                <div class="selected-vehicles__horizontal-slider-container">
                    <div class="selected-vehicles__horizontal-slider">
                        <VehicleCard
                            v-for="(vehicle, vehicleIndex) in type.vehicles"
                            :key="vehicleIndex"
                            v-near-viewport-fold-detection="() => setNearViewportFoldCards(index)"
                            :vehicle="vehicle"
                            :index-in-list="vehicleIndex"
                            :origin="listName"
                            class="selected-vehicles__horizontal-slide"
                            :lazy-load-card-images="true"
                            :is-near-viewport-fold="nearViewportFoldCards.includes(index)"
                        />
                    </div>
                </div>
            </div>
        </template>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import { ASSET_SUB_TYPE_MOTORHOME, ASSET_SUB_TYPE_CAMPERVAN, ASSET_SUB_TYPE_CARAVAN } from '~/config/assetTypes';
import VehicleList from '~/components/vehicles/VehicleList.vue';
import LoadingIndicator from '~/components/LoadingIndicator.vue';
import SelectedVehiclesTitle from '~/components/sections/SelectedVehiclesTitle.vue';

export default {
    name: 'SelectedVehicles',
    components: {
        VehicleList,
        LoadingIndicator,
        SelectedVehiclesTitle,
        VehicleCard: () => import('~/components/vehicles/VehicleCard.vue'),
    },
    props: {
        listName: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            selected: [],
            selectedBest: [],
            nearViewportFoldCards: [],
        };
    },
    async fetch() {
        try {
            // Store not updated at this point so we manually get 'siteCountryCode'
            const locale = new Intl.Locale(this._i18n.localeProperties.iso);
            const countryCode = locale.region || null;

            const vehicleTypesData = [
                {
                    filters: { isFeatured: true, subType: ASSET_SUB_TYPE_MOTORHOME },
                    key: ASSET_SUB_TYPE_MOTORHOME,
                },
                {
                    filters: { isFeatured: true, subType: ASSET_SUB_TYPE_CARAVAN },
                    key: ASSET_SUB_TYPE_CARAVAN,
                },
                {
                    filters: { isFeatured: true, subType: ASSET_SUB_TYPE_CAMPERVAN },
                    key: ASSET_SUB_TYPE_CAMPERVAN,
                },
            ];

            const promises = vehicleTypesData.map(vehicleType =>
                this.$vehicleService.getVehicles(
                    vehicleType.filters,
                    countryCode,
                    null,
                    3, // Number of vehicles
                    'owner'
                )
            );

            const results = await Promise.all(promises);

            this.selected = results.map((result, index) => ({
                type: vehicleTypesData[index].key,
                vehicles: result.vehicles,
                subTypeTotal: result.pagination.subTypeTotal,
            }));

            this.selectedBest = results.filter(result => result.vehicles.length > 0).map(result => result.vehicles[0]);
        } catch (err) {
            this.$sentry.captureException(err);
        }
    },
    computed: {
        ...mapGetters(['isLargeScreen', 'platformVehicles']),
        titles() {
            return [
                {
                    iconName: ASSET_SUB_TYPE_MOTORHOME,
                    noOfVehicles: this.$tc(
                        'common.motorhome_numbered',
                        this.selected.find(e => e.type === ASSET_SUB_TYPE_MOTORHOME)?.subTypeTotal
                    ),
                    linkText: this.$t('sections.selected_vehicles.all_motorhomes'),
                    linkPath: this.$t('common.motorhomes_page_path'),
                },
                {
                    iconName: ASSET_SUB_TYPE_CARAVAN,
                    noOfVehicles: this.$tc(
                        'common.caravan_numbered',
                        this.selected.find(e => e.type === ASSET_SUB_TYPE_CARAVAN)?.subTypeTotal
                    ),
                    linkText: this.$t('sections.selected_vehicles.all_caravans'),
                    linkPath: this.$t('common.caravans_page_path'),
                },
                {
                    iconName: ASSET_SUB_TYPE_CAMPERVAN,
                    noOfVehicles: this.$tc(
                        'common.campervan_numbered',
                        this.selected.find(e => e.type === ASSET_SUB_TYPE_CAMPERVAN)?.subTypeTotal
                    ),
                    linkText: this.$t('sections.selected_vehicles.all_campervans'),
                    linkPath: this.$t('common.campervans_page_path'),
                },
            ];
        },
    },
    methods: {
        setNearViewportFoldCards(index) {
            if (!this.nearViewportFoldCards.includes(index)) {
                this.nearViewportFoldCards.push(index);
            }
        },
    },
};
</script>

<style lang="scss">
@import '@/sass/_variables.scss';

.selected-vehicles.section {
    .selected {
        display: none;

        @include md {
            display: flex;
        }
    }

    .vehicle-list__item {
        max-width: 100%;
        width: 100%;
        margin: 0 10px;

        @include sm {
            max-width: 60%;
            width: 60%;
        }

        @include md {
            max-width: 47%;
            width: 47%;
        }

        @include lg {
            max-width: 30%;
            width: 30%;
        }
    }
}

.selected-vehicles {
    &__loading {
        margin: 115px;
    }

    &__mobile-wrapper {
        padding: 0 10px;
        margin-bottom: 1.5rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__horizontal-slider-container {
        position: relative;
        overflow: hidden;
        overflow-x: scroll;

        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }

    &__horizontal-slider {
        $slide-width: 85%;
        $space-between: 1rem;
        $num-of-slides: 3;

        display: flex;
        width: calc(#{$slide-width}*#{$num-of-slides} - #{$space-between}* (#{$num-of-slides} - 1));

        .selected-vehicles__horizontal-slide {
            width: $slide-width;
            max-width: $slide-width;
            overflow-x: hidden; // set because of text truncate for camper name

            &:not(:last-child) {
                margin-right: $space-between;
            }
        }
    }
}
</style>
